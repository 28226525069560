<template>
  <div id="app">
    <Main />
  </div>
</template>

<script>
import Main from './views/Main.vue'
import axios from 'axios'
export default {
  name: 'App',
  data(){
    return{
      path: ''
    }
  },
  components: {
    Main
  },
  metaInfo: {
    title: window.language == 'lt' ? 'eLKlasė' : 'LKclass',
    titleTemplate: '%s',
    meta:[
      {
        name: 'description', content: window.language == 'lt' ? 'eLKlasė – tai viena mokymo(si) platforma – ir moksleiviams, ir pedagogams! Mokytojai gali susikurti neribotą kiekį įdomių skaitmenizuotų pamokų. „eLKlasėje“ galima sudaryti matematikos, rašymo ar kitas užduotis darbui klasėje ar užduoti namų darbams.' : 'LKclass is a single learning platform for students and educators! Teachers can create an unlimited number of engaging digitised lessons. In "LKclass", maths, writing or other tasks can be set up for classroom work or homework.',
      }
    ]
  },
  mounted(){
    document.documentElement.style.setProperty(
      `--main-site-font`,
      "'Noto Sans', sans-serif"
    );
    let initial_width = window.innerWidth;
    this.$store.state.windowWidth = initial_width;
    window.addEventListener('resize', () => {
      this.$store.state.windowWidth = window.innerWidth;
    });
    this.path = window.location.pathname.split('/');
    if(this.path[1] && this.path[1].length == 5){
      this.checkLoginLink(this.path[1]);
    }
  },
  methods:{
    checkLoginLink(key){
      axios.get(`https://api.elklase.lt/api/login/check/${key}`)
            .then((res)=>{
              if(res.data.status == 'success'){
                window.location.href = res.data.url;
              }
            })
            .catch((err)=>{
              console.log(err);
            })
    }
  },
  beforeDestroy(){
    window.removeEventListener('resize');
  }
}
</script>

<style>
html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

body{
  margin: 0;
  overflow: hidden;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #28345C;
  padding: 0rem;
  font-family: var(--main-site-font);
}
@media (max-width: 768px){
  body{
    overflow-y: auto;
  }
}
</style>
