<template>
    <div>
        <div class="topic-card login" v-if="cardType=='login'">
            <div class="card-container">
                <h3>{{$translation.card_login1}}</h3>
                <p>{{$translation.card_login2}}</p>
                <div class="input-wrapper">
                    <form class="form-group">
                    <div class="input-container">
                        <input 
                            type="text" 
                            :placeholder="$translation.card_login_emailUsername" 
                            v-model="$v.login.emailOrUsername.$model"
                            @keypress.enter="loginUser"
                        >
                    </div>
                     <span class="error" v-if="$v.login.$anyDirty && !$v.login.emailOrUsername.required">
                        {{ $translation.card_validation_required_mobile}}
                    </span>
                    <span class="error" v-if="!$v.login.emailOrUsername.minLength">
                        {{ $translation.card_validation_min_mobile.replace('[n]', $v.login.emailOrUsername.$params.minLength.min)}} 
                    </span>
                    <span class="error" v-if="!$v.login.emailOrUsername.maxLength">
                        {{ $translation.card_validation_max.replace('[n]', $v.login.emailOrUsername.$params.maxLength.max)}} 
                    </span>
                    <div class="input-container">
                     <input 
                        type="password" 
                        :placeholder="$translation.card_login_password" 
                        v-model="$v.login.password.$model"
                        @keypress.enter="loginUser"
                     >
                    </div>
                     <span class="error" v-if="$v.login.$anyDirty && !$v.login.password.required ">
                        {{ $translation.card_validation_required_mobile}}
                    </span>
                    <span class="error" v-if="!$v.login.password.minLength">
                        {{ $translation.card_validation_min_mobile.replace('[n]', $v.login.password.$params.minLength.min)}} 
                    </span>
                    <span class="error" v-if="loginErrorMessage.length > 0">
                        {{ loginErrorMessage}} 
                    </span>
      
                    <span class="forgot-password" @click="forgotPasswordModalOpen = true">{{$translation.card_forgot_password}}</span>
                    </form>
                </div>
                <div class="button md ma-2rem"  @click="loginUser">{{$translation.card_submit}}</div>
                <div class="try-out-container">
                    <h4>{{$translation.card_login_register1}}</h4>
                    <span  @click="$emit('jumpTopic', 5)">{{$translation.card_login_register2}}</span>
                </div>
            </div>
            <ForgotPasswordModal v-if="forgotPasswordModalOpen" @closeModal="forgotPasswordModalOpen = false" /> 
        </div>
        <div class="topic-card" v-else>
            <div class="card-container">
                <h3>{{$translation.card_register_title}}</h3>
                <div class="input-wrapper">
                    <span>{{$translation.card_register_name}}</span>
                    <div class="input-container">
                        <v-popover
                            v-if="currentTopic == 5 && $store.state.windowWidth > 768"
                            offset="26"
                            :open="$v.register.name.$invalid && $v.register.name.$anyDirty"
                            placement="left-center"
                            popoverClass="popover-container"
                            :autoHide='false'
                        >
                            <template slot="popover">
                                <span>"{{$translation.card_register_name}}"</span>
                                <span>{{$translation.card_validation_field_req}}</span>
                                <ul>
                                    <li v-if="!$v.register.name.required">
                                    {{ $translation.card_validation_required}}
                                    </li>
                                    <li v-if="!$v.register.name.minLength">
                                        {{ $translation.card_validation_min}} {{$v.register.name.$params.minLength.min}}
                                    </li>
                                    <li v-if="!$v.register.name.maxLength">
                                        {{ $translation.card_validation_max}} {{$v.register.name.$params.maxLength.max}}
                                    </li>
                                </ul>
                            </template>
                        </v-popover>
                        <input type="text" :placeholder="$translation.card_register_name" v-model="$v.register.name.$model">

                    </div>
                     <span class="error" v-if="$v.register.$anyDirty && !$v.register.name.required && $store.state.windowWidth <= 768">
                        {{ $translation.card_validation_required_mobile}}
                    </span>
                    <span class="error" v-if="!$v.register.name.minLength && $store.state.windowWidth <= 768">
                        {{ $translation.card_validation_min_mobile.replace('[n]', $v.register.name.$params.minLength.min)}} 
                    </span>
                    <span class="error" v-if="!$v.register.name.maxLength && $store.state.windowWidth <= 768">
                        {{ $translation.card_validation_max.replace('[n]', $v.register.name.$params.maxLength.max)}} 
                    </span>
                    <span>{{$translation.card_register_last_name}}</span>
                    <div class="input-container">
                         <v-popover
                            v-if="currentTopic == 5 && $store.state.windowWidth > 768"
                            offset="26"
                            :open="$v.register.surname.$invalid && $v.register.surname.$anyDirty"
                            placement="left-center"
                            popoverClass="popover-container"
                            :autoHide='false'
                        >
                            <template slot="popover">
                                <span>"{{$translation.card_register_last_name}}"</span>
                                <span>{{$translation.card_validation_field_req}}</span>
                                <ul>
                                    <li v-if="!$v.register.surname.required">
                                    {{ $translation.card_validation_required}}
                                    </li>
                                    <li v-if="!$v.register.surname.minLength">
                                        {{ $translation.card_validation_min}} {{$v.register.surname.$params.minLength.min}}
                                    </li>
                                    <li v-if="!$v.register.surname.maxLength">
                                        {{ $translation.card_validation_max}} {{$v.register.surname.$params.maxLength.max}}
                                    </li>
                                </ul>
                            </template>
                        </v-popover>
                        <input type="text" :placeholder="$translation.card_register_last_name" v-model="$v.register.surname.$model">
                    </div>
                    <span class="error" v-if="$v.register.$anyDirty && !$v.register.name.required && $store.state.windowWidth <= 768">
                        {{ $translation.card_validation_required_mobile}}
                    </span>
                    <span class="error" v-if="!$v.register.surname.minLength && $store.state.windowWidth <= 768">
                        {{ $translation.card_validation_min_mobile.replace('[n]', $v.register.surname.$params.minLength.min)}} 
                    </span>
                    <span class="error" v-if="!$v.register.surname.maxLength && $store.state.windowWidth <= 768">
                        {{ $translation.card_validation_max.replace('[n]', $v.register.surname.$params.maxLength.max)}} 
                    </span>
                    <span>{{$translation.card_register_email}}</span>
                    <div class="input-container">
                         <v-popover
                            v-if="currentTopic == 5 && $store.state.windowWidth > 768"
                            offset="26"
                            :open="$v.register.email.$invalid && $v.register.email.$anyDirty"
                            placement="left-center"
                            popoverClass="popover-container"
                            :autoHide='false'
                        >
                            <template slot="popover">
                                <span>"{{$translation.card_register_email}}"</span>
                                <span>{{$translation.card_validation_field_req}}</span>
                                <ul>
                                    <li v-if="!$v.register.email.required">
                                    {{ $translation.card_validation_required}}
                                    </li>
                                    <li v-if="!$v.register.email.email">
                                    {{ $translation.card_validation_email}}
                                    </li>
                                    <li v-if="!$v.register.email.minLength">
                                        {{ $translation.card_validation_min}} {{$v.register.email.$params.minLength.min}}
                                    </li>
                                    <li v-if="!$v.register.email.maxLength">
                                        {{ $translation.card_validation_max}} {{$v.register.email.$params.maxLength.max}}
                                    </li>
                                </ul>
                            </template>
                        </v-popover>
                        <input type="text" :placeholder="$translation.card_register_email" v-model="$v.register.email.$model">
                    </div>
                     <span class="error" v-if="$v.register.$anyDirty && !$v.register.name.required && $store.state.windowWidth <= 768">
                        {{ $translation.card_validation_required_mobile}}
                    </span>
                     <span class="error" v-if="!$v.register.email.email && $store.state.windowWidth <= 768">
                        {{ $translation.card_validation_email_mobile}}
                    </span>
                    <span class="error" v-if="!$v.register.email.minLength && $store.state.windowWidth <= 768">
                        {{ $translation.card_validation_min_mobile.replace('[n]', $v.register.email.$params.minLength.min)}} 
                    </span>
                    <span class="error" v-if="!$v.register.email.maxLength && $store.state.windowWidth <= 768">
                        {{ $translation.card_validation_max.replace('[n]', $v.register.email.$params.maxLength.max)}} 
                    </span>
                    <span>{{$translation.card_register_phone}}</span>
                    <div class="input-container">
                         <v-popover
                            v-if="currentTopic == 5 && $store.state.windowWidth > 768"
                            offset="26"
                            :open="$v.register.phone.$invalid && $v.register.phone.$anyDirty"
                            placement="left-center"
                            popoverClass="popover-container"
                            :autoHide='false'
                        >
                            <template slot="popover">
                                <span>"{{$translation.card_register_phone}}"</span>
                                <span>{{$translation.card_validation_field_req}}</span>
                                <ul>
                                    <li v-if="!$v.register.phone.required">
                                    {{ $translation.card_validation_required}}
                                    </li>
                                    <li v-if="!$v.register.phone.minLength">
                                        {{ $translation.card_validation_min}} {{$v.register.phone.$params.minLength.min}}
                                    </li>
                                    <li v-if="!$v.register.phone.maxLength">
                                        {{ $translation.card_validation_max}} {{$v.register.phone.$params.maxLength.max}}
                                    </li>
                                </ul>
                            </template>
                        </v-popover>
                        <input type="text" :placeholder="$translation.card_register_phone" v-model="$v.register.phone.$model">
                    </div>
                     <span class="error" v-if="$v.register.$anyDirty && !$v.register.name.required && $store.state.windowWidth <= 768">
                        {{ $translation.card_validation_required_mobile}}
                    </span>
                    <span class="error" v-if="!$v.register.phone.minLength && $store.state.windowWidth <= 768">
                        {{ $translation.card_validation_min_mobile.replace('[n]', $v.register.phone.$params.minLength.min)}} 
                    </span>
                    <span class="error" v-if="!$v.register.phone.maxLength && $store.state.windowWidth <= 768">
                        {{ $translation.card_validation_max.replace('[n]', $v.register.phone.$params.maxLength.max)}} 
                    </span>
                    <span>{{$translation.card_register_school}}</span>
                    <div class="input-container">
                         <v-popover
                            v-if="currentTopic == 5 && $store.state.windowWidth > 768"
                            offset="26"
                            :open="$v.register.school.$invalid && $v.register.school.$anyDirty"
                            placement="left-center"
                            popoverClass="popover-container"
                            :autoHide='false'
                        >
                            <template slot="popover">
                                <span>"{{$translation.card_register_school}}"</span>
                                <span>{{$translation.card_validation_field_req}}</span>
                                <ul>
                                    <li v-if="!$v.register.school.required">
                                    {{ $translation.card_validation_required}}
                                    </li>
                                    <li v-if="!$v.register.school.minLength">
                                        {{ $translation.card_validation_min}} {{$v.register.school.$params.minLength.min}}
                                    </li>
                                    <li v-if="!$v.register.school.maxLength">
                                        {{ $translation.card_validation_max}} {{$v.register.school.$params.maxLength.max}}
                                    </li>
                                </ul>
                            </template>
                        </v-popover>
                        <input type="text" :placeholder="$translation.card_register_school" v-model="$v.register.school.$model">
                    </div>
                     <span class="error" v-if="$v.register.$anyDirty && !$v.register.name.required && $store.state.windowWidth <= 768">
                        {{ $translation.card_validation_required_mobile}}
                    </span>
                    <span class="error" v-if="!$v.register.school.minLength && $store.state.windowWidth <= 768">
                        {{ $translation.card_validation_min_mobile.replace('[n]', $v.register.school.$params.minLength.min)}} 
                    </span>
                    <span class="error" v-if="!$v.register.school.maxLength && $store.state.windowWidth <= 768">
                        {{ $translation.card_validation_max.replace('[n]', $v.register.school.$params.maxLength.max)}} 
                    </span>
                </div>
                <div class="button md ma-2rem" @click="registerSchool">
                    {{!registerLoading ? $translation.card_register_submit : ''}}
                    <RollerLoader v-if="registerLoading" :size="23" :color="'#2C3E50'" />
                    </div>
                <transition name="fade" mode="in-out">
                    <div class="message" v-if="showMessage">{{$translation.card_register_request_sent}}</div>
                </transition>
                <span class="acc-exists">{{$translation.card_register_have_account}}<strong @click="$emit('jumpTopic', 1)"> {{$translation.card_register_login_here}} </strong></span>
            </div>
        </div>

        <!-- <Popover
            v-if="currentTopic == 5 && $v.register.name.$invalid && $v.register.name.$anyDirty"
        >
            <h3>Vardas</h3>
            <span>Laukelis turi turėti:</span>
            <ul>
            <li>
                Bent 2 simbolius
            </li>
            </ul>
        </Popover> -->
    </div>
</template>

<script>
import axios from 'axios';
import { required, minLength, maxLength, email } from 'vuelidate/lib/validators'
import ForgotPasswordModal from '@/components/ForgotPasswordModal.vue'
import { RollerLoader } from 'vue-spinners-css';



export default {
    name: 'Card',
    components:{
        ForgotPasswordModal,
        RollerLoader,
    },
    validations: {
        register: {
            name:{
                required,
                minLength: minLength(2),
                maxLength: maxLength(255),
            },
            surname:{
                required,
                minLength: minLength(2),
                maxLength: maxLength(255),
            },
            email:{
                required,
                email,
                minLength: minLength(6),
                maxLength: maxLength(255),
            },
            phone:{
                required,
                minLength: minLength(6),
                maxLength: maxLength(16),
            },
            school:{
                required,
                minLength: minLength(2),
                maxLength: maxLength(255),
            }
        },
        login: {
            emailOrUsername:{
                required,
                minLength: minLength(2),
                maxLength: maxLength(255),
            },
            password:{
                required,
                minLength: minLength(6),
            }
        }
    },
    data(){
        return{
            login:{
                emailOrUsername: '',
                password: ''
            },
            register:{
                name: '',
                surname: '',
                email: '',
                phone: '',
                school: ''
            },
            showMessage: false,
            forgotPasswordModalOpen: false,
            registerLoading: false,
            loginErrorMessage: '',
        }
    },

    props:{
        'cardType':{
            type: String,
        },
        currentTopic:{
            type:Number,
        }
    },
    methods:{
        loginUser(){
            this.loginErrorMessage = '';
            this.$v.login.$touch();
            if(this.$v.login.$anyError)
                return;
            axios.post('https://api.elklasė.lt/api/lms/login',
            {
                email: this.login.emailOrUsername,
                password: this.login.password
            })
            .then((res)=>{
                this.$v.login.$reset();
                console.log(res);
                //temporary
                if(window.language == 'lt'){
                    // this.$cookies.set('access_token',res.data.data.token, null, null, '.'+login_domain);
                    window.location.href = 'https://'+res.data.data.school+'.elklase.lt/login/'+res.data.data.token;
                }
                else{
                    // this.$cookies.set('access_token',res.data.data.token, null, null, '.lkclass.com');
                    // this.$cookies.set('access_token',res.data.data.token, null, null, '.lkclass.com');
                    window.location.href = 'https://'+res.data.data.school+'.lkclass.com/login/'+res.data.data.token;
                }
            })
            .catch((err)=>{
                // if(err.data);
                console.log(err);
                if(err.response.data && err.response.data.error){
                    if(err.response.data.error == 'login.wrong_credentials'){
                        this.loginErrorMessage = this.$translation.card_validation_wrong_credentials;
                    }
                }
            })
        },
        registerSchool(){
            this.$v.register.$touch()
            if(this.$v.register.$anyError || this.$v.register.$invalid)
                return;
            this.registerLoading = true;

             axios.post('https://api.elklasė.lt/api/register/school',
            {
                name: this.register.name,
                last_name: this.register.surname,
                email: this.register.email,
                phone: this.register.phone,
                school: this.register.school,
            })
            .then(()=>{
                this.showMessage = true;
                this.register={
                    name: '',
                    surname: '',
                    email: '',
                    phone: '',
                    school: ''
                };
                this.$v.register.$reset();
                setTimeout(()=>{
                    this.showMessage = false;
                }, 2000);
                this.registerLoading = false;

            })
            .catch((err)=>{
                this.registerLoading = false;
                console.log(err.response.errors);
            })
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_inputs.scss";
@import "@/assets/scss/_button.scss";
    h3{
        display: inline-flex;
        border-bottom: 0.35rem solid #A9B9EE;
        padding-bottom: 0.75rem;
        margin: 0;
        font-weight: 900;
   }
 .topic-card{
    position: relative;
    background: linear-gradient(0deg, rgba(181,195,241,1) 78%, rgb(213, 223, 253) 100%);
    border-radius: 1.5rem;
    max-width: 27rem;
    margin: 2rem auto;
    min-width: 17rem;
    box-shadow: 0px 3px 8px 1px #acb5d2f0;
    // overflow: hidden;
    &.login{
        min-width: 16vw;
        max-width: 24vw;
    }
    div, h3, p{
        position: relative;
        z-index: 2;
    }
    p{
        font-size: clamp(16px, 1vw, 1.5em);
    }
    .try-out-container{
        margin-top: 5rem;
        h4{
            color: white;
            font-weight: 900;
            font-size: clamp(16px, 1.5vw, 2vw);
            margin: 0;
        }
        span{
            font-size: clamp(12px, 1vw, 1.5vw);
            font-weight: 700;
            cursor: pointer;
        }
        
    }
    .card-container{
        overflow: hidden;
        padding: 2.75rem;
        max-width: 100%;
        border-radius: 1.5rem;
        &::after{
            content: "";
            display: flex;
            position: absolute;
            bottom: -33%;
            left: -2%;
            width: 104%;
            height: 65%;
            border-radius: 50%;
            background: #C8D2F5;
            z-index: 1;
        }
    }
    &::before{
        position: absolute;
        content: '';
        left: -4px;
        top: -4px;
        border-radius: 1.5rem;
        height: calc(100% + 8px);
        width: calc(100% + 8px);
        background: #bcc9f524;
        z-index: -1;
    }
     .message{
        position: absolute;
        bottom: 2rem;
        margin: 0 auto;
        left: 0;
        background: white;
        display: flex;
        justify-content: center;
        padding: 0.5rem 2rem;
        width: fit-content;
        border-radius: 2rem;
        right: 0;
        z-index: 6;
    }
}
 .input-wrapper{
       display: flex;
       flex-direction: column;
       .forgot-password{
           display: flex;
           cursor: pointer;
           margin-left: 1.5rem;
           margin-top: 0;
           font-size: clamp(12px, 0.65vw, 16px);
           padding: 0;
       }
       span{
           display: flex;
           text-align: left;
           justify-content: flex-start;
           margin-top: 0.25rem;
           margin-bottom: 0rem;
           padding: 0 1.3rem;
           font-size: clamp(14px, 1vw, 18px);
       }
   }
   .form-group{
        display: flex;
        flex-direction: column;
   }
//    input{
//        border-radius: 2rem;
//        padding: 0.75rem 2rem;
//        border: none;
//        background: white;
//        margin: 0.5rem 0;
//        box-shadow: 0px 0.1rem 0.2rem 0.1rem #99999975;
//        &::placeholder{
//            color:#24345f71
//        }
//    }

   .input-container{
       display: flex;
       margin: 0.5rem 0 0.5rem 0;
       width: 100%;
   }
   .acc-exists{
        display: flex;
        position: relative;
        justify-content: center;
        z-index: 5;
        color: white;
        font-size: clamp(14px, 1vw, 18px);
        strong{
            margin: 0 0.25rem;
            cursor: pointer;
            color: #28345C;
        }
   }
  
   @media (max-width: 768px){
       .topic-card{
          .card-container{
            padding: 1.75rem;
            &::after{
                width: 122%;
                height: 65%;
                left: -11%;
                max-height: 22rem;
            }
          }
           .try-out-container{
               margin: 0;
               h4{
                   font-size: clamp(14px, 6vw, 2em);
               }
               span{
                    font-size: clamp(14px, 4vw, 2em);
                    font-weight: 900;
               }
           }
            &.login{
                min-width: 16vw;
                max-width: 100%;
            }
       }
       .input-wrapper{
           .forgot-password{
               justify-content: flex-end;
               font-size: clamp(12px, 4vw, 2em);
                margin: 0.5rem 0;
           }
       }
       .button{
           width: 100%;
       }
       h3{
           font-size: clamp(1em, 6vw, 2em);
       }
       p{
            font-size: clamp(1em, 5vw, 2em);
       }
       .acc-exists{
            flex-direction: column;
            color:white;
            font-size: clamp(0.5em, 5vw, 2em);
            line-height: 1.5;
            strong{
                font-weight: 900;
                color:#28345C;
            }
       }
   }
   .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
  .error{
      color: rgb(240, 86, 86)
  }
</style>
<style lang="scss">
  .popover-container{
        background: white;
        padding: 0.5rem;
        border-radius: 1rem;
        border:2px solid #B5C3F1;
        left: -22rem;
        top: 3rem;
        font-family: var(--main-site-font);
        // margin-top: 1.35rem;
        ul{
            margin: 0.25rem;

        }
    }
</style>